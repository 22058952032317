import React, { useEffect, useState } from "react";
import FileSelection2 from "./FileSelection2";
import { Button, Input, Label } from "reactstrap";
import Swal from "sweetalert2";
import { getCookie } from "../../utils/Utils";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { toast } from "react-toastify";
import PuffLoader from "react-spinners/PuffLoader";
import defaultImage from "../../images/uploadImage.png";
import { BlockTitle } from "../../components/Component";
import Head from "../../layout/head/Head";

let token = process.env.REACT_APP_AUTH_TOKEN;

const Setting = () => {
  const [data, setData] = useState(null);
  const [btnloader, setButtonloader] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formdata, setFormdata] = useState({
    meta_title: "",
    meta_description: "",
    meta_url: "",
  });

  const fetchData = async () => {
    try {
      const authToken = getCookie("authToken");
      const formData = new FormData();
      formData.append("field_id", "favicon");
      formData.append("field_id", "sidebar_logo");
      formData.append("field_id", "public_logo");
      formData.append("field_id", "logo");
      formData.append("field_id", "blog_header_image");
      formData.append("field_id", "meta_image");
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-setting`, {
        method: "POST",
        // body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData.data);
      setFormdata({
        meta_title: responseData.data?.meta_title?.field_value || "",
        meta_description: responseData.data?.meta_description?.field_value || "",
        meta_url: responseData.data?.meta_url?.field_value || "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You won't be able to delete it",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes change it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setButtonloader(true);
        const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

        const formData = new FormData();
        const isValidFileType = (file) => allowedFileTypes.includes(file.type);

        const handleFileInput = (inputElement, formDataKey) => {
          const file = inputElement.files[0];
          if (file) {
            if (isValidFileType(file)) {
              formData.append(formDataKey, file);
            } else {
              toast.error(`Invalid file type: ${file.type}. Allowed types are jpg, png, jpeg, gif.`);
            }
          }
        };
        handleFileInput(document.getElementById("sidebarLogoId"), "sidebar_logo");
        handleFileInput(document.getElementById("faviconLogoId"), "favicon_logo");
        handleFileInput(document.getElementById("publicpageLogoId"), "public_page_logo");
        handleFileInput(document.getElementById("authLogoId"), "auth_logo");
        handleFileInput(document.getElementById("blogHeaderBackgroundImage"), "blog_header_image");
        handleFileInput(document.getElementById("metaImage"), "meta_image");

        formData.append("meta_title", formdata.meta_title);
        formData.append("meta_description", formdata.meta_description);
        formData.append("meta_url", formdata.meta_url);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/sitesetting/add-sitesetting`, {
            method: "POST",
            headers: {
              authToken: getCookie("authToken", token),
            },
            body: formData,
          });

          if (response.ok) {
            toast.success("Site setting updated successfully!");
          } else {
            const errorData = await response.json();
            toast.error(`Error: ${errorData.message || "Failed to upload files"}`);
          }
        } catch (error) {
          console.error("API call error:", error);
          toast.error("An error occurred during file upload.");
        } finally {
          setButtonloader(false);
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <Head title="SiteSettings CMS" />
      <div className="row my-3 mx-1">
        <BlockTitle tag="h4">Logo Selection</BlockTitle>
        <div className="fs-18px text-soft">Logo Selection for respected sections</div>
      </div>
      {loading ? (
        <ShimmerSimpleGallery card imageHeight={250} caption row={1} col={4} />
      ) : (
        <div className="container-fluid">
          <div className="row border border-grey my-2 d-flex">
            {/* justify-center mx-2 */}
            {data ? (
              <>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-start flex-column my-3">
                    <div className="fs-18px fw-bold">Sidebar Logo</div>
                    <FileSelection2 filePath={data?.sidebar_logo?.image_data} inputId="sidebarLogoId" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Favicon Logo</div>
                    <FileSelection2 filePath={data?.favicon_logo?.image_data} inputId="faviconLogoId" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Public Page Logo</div>
                    <FileSelection2 filePath={data?.public_page_logo?.image_data} inputId="publicpageLogoId" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Auth Pages Logo</div>
                    <FileSelection2 filePath={data?.auth_logo?.image_data} inputId="authLogoId" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Blog Header Background Image</div>
                    <FileSelection2
                      filePath={data?.blog_header_image?.image_data}
                      inputId="blogHeaderBackgroundImage"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Meta Image</div>
                    <FileSelection2 filePath={data?.meta_image?.image_data} inputId="metaImage" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Meta Description</div>
                    <textarea
                      className="no-resize form-control"
                      type="textarea"
                      id="meta_description"
                      name="meta_description"
                      defaultValue="Large text area content"
                      value={formdata.meta_description}
                      onChange={(e) => setFormdata({ ...formdata, meta_description: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Meta Title</div>
                    <Input
                      id="meta_title"
                      name="meta_title"
                      placeholder="Enter meta title here..."
                      type="text"
                      value={formdata.meta_title}
                      onChange={(e) => setFormdata({ ...formdata, meta_title: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Meta URL</div>
                    <Input
                      id="meta_url"
                      name="meta_url"
                      placeholder="Enter meta URL here..."
                      type="text"
                      value={formdata.meta_url}
                      onChange={(e) => setFormdata({ ...formdata, meta_url: e.target.value })}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-start flex-column my-3">
                    <div className="fs-18px fw-bold">Sidebar Logo</div>
                    <FileSelection2 filePath={defaultImage} inputId="sidebarLogoId" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Favicon Logo</div>
                    <FileSelection2 filePath={defaultImage} inputId="faviconLogoId" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Public Page Logo</div>
                    <FileSelection2 filePath={defaultImage} inputId="publicpageLogoId" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Auth Pages Logo</div>
                    <FileSelection2 filePath={defaultImage} inputId="authLogoId" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Blog Header Background Image</div>
                    <FileSelection2 filePath={defaultImage} inputId="blogHeaderBackgroundImage" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-center flex-column my-3">
                    <div className="fs-18px fw-bold">Meta Image</div>
                    <FileSelection2 filePath={defaultImage} inputId="metaImage" />
                  </div>
                </div>
              </>
            )}
            <div className="row my-2">
              <div className="d-flex align-items-center justify-content-center">
                <Button color="primary" onClick={handleSubmit} disabled={btnloader}>
                  {btnloader ? <PuffLoader speedMultiplier={3} size={25} color="#39ff00" /> : "SAVE"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Setting;
