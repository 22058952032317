import React, { useLayoutEffect, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// import Homepage from "../pages/Homepage";


import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import LayoutApp from "../layout/Index-app";

import LayoutNoSidebar from "../layout/Index-nosidebar";
import UserDatatable from "../pages/user/UserList";
import AdminManageCMS from "../pages/user/AdminManageCMS";
import AddUser from "../pages/user/AddUser";
import EditUser from "../pages/user/EditUser";
import RecentUsers from "../pages/user/RecentUsers";
import UserViewDetails from "../pages/user/UserViewDetails";
import UsersMail from "../pages/user/UsersMail";
import UserLog from "../pages/user/UserLog";
import UserRoles from "../pages/user/UserRoles";
import AdminNewsList from "../pages/user/AdminNewsList";
import CreateNews from "../pages/user/CreateNews";
import Setting from "../pages/user/Setting";
import Contact from "../pages/Contact-Form/Contact";
import Cookies from "js-cookie";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  useEffect(() => {
    let at = Cookies.get("authToken");
    if (at) {

      const BTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    } else {
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    }
  }, []);

  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<LayoutApp app={{ icon: "dashboard", theme: "bg-purple-dim", text: "Dashboard" }} />}
      >
        <Route index element={<AdminNewsList />}></Route>
        <Route element={<UserDatatable />}></Route>
        <Route path="/user-list" element={<UserDatatable />}></Route>
        <Route path="/admin-manage-cms" element={<AdminManageCMS />}></Route>
        <Route path="user-list/edit/:userId" element={<EditUser />}></Route>
        <Route path="user-list/view/:userId" element={<UserViewDetails />}></Route>
        <Route path="create" element={<AddUser />}></Route>
        <Route path="/recent-user" element={<RecentUsers />}></Route>
        <Route path="/admin-user-mail" element={<UsersMail />}></Route>
        <Route path="/user-log" element={<UserLog />}></Route>
        <Route path="/user-roles" element={<UserRoles />}></Route>
        <Route path="user-list/create" element={<AddUser />}></Route>
        <Route path="/createblog" element={<CreateNews />}></Route>
        <Route path="/admin-manage-cms/admin-news-list" element={<AdminNewsList />}></Route>
        <Route path="/admin-manage-cms/admin-news-list/edit/:userId" element={<CreateNews />}></Route>
        <Route path="/edit/:userId" element={<CreateNews />}></Route>
        <Route path="/admin-manage-cms/admin-news-list/:userId" element={<AdminNewsList />}></Route>
        <Route path="/setting" element={<Setting />}></Route>
        <Route path="/contact-form" element={<Contact />}></Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
